@import '../../../themes/variables/mixins.scss';


.pinBox{
    margin: 5px !important;
    width: 60px !important;

    .MuiInputBase-input{
        text-align: center;
    }
}