@import '../../themes/variables/mixins.scss';

.payment-main-section{

    .qr-section{
        background: $white;
        border-radius: 6px;
        
    }

    .qr-img{
        position: relative;
        overflow: hidden;
        width: 140px;
        height: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 140px;

        img {
            width: 136px;
            position: absolute;
            z-index: 1;
            top: 2px;
            left: 2px;
        }
    }

    .qr-img:before {
        position: absolute;
        left: 1px;
        right: 1px;
        top: 1px;
        bottom: 1px;
        border-width: 1px;
        border-style: solid;
        content: '';
        border-color: $primary;
    }

    .qr-img:after {
        position: absolute;
        width: 120%;
        height: 120%;
        left: -10%;
        top: -10%;
        -webkit-transform: rotateZ(45deg);
        -ms-transform: rotate(45deg);
        transform: rotateZ(45deg);
        content: '';
        background: #fff;
    }

    .blur{
        -webkit-filter: blur(1px);
        filter: blur(1px);
        opacity: 0.5;
    }

    .new-qr-btn{
        z-index: 1;
        position: absolute;
        text-align: center;
    }

    .qr-desc{
        margin: 10px 0px 10px 20px;

        .ven-list{
            width: 110px;
            padding: 10px 0px 10px 0px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;

            .ven-logo{
                width: 20px;
                height: 20px;
            }

        }

    }

    .vpaScreen{
        display: none;
        transition: 2s;
    }

    .vpaScreen.show{
        display: inline;
        transition: 5s;
    }

    .save-payment-section{

        .save-payment-card{
            background: $white;
            border-radius: 6px;
            display: flex;
            flex-direction: row;
            padding: 8px 12px;
        }

        .save-payment-upi{
            background: $white;
            border-radius: 6px;
            display: flex;
            flex-direction: row;
            padding: 8px 12px;
        }
    }

    .payment-options{

        .payment-mode{
            background: $white;
            border-radius: 6px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 12px;
        }

        .payment-mode:hover{
            background-color: rgb(56 58 59 / 11%);
        }
    }


    //.vpaPaymentModalBody{
        //padding: 20px 10px 10px 10px;
    //}
}