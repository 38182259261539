$white: #fff;
$black: #1d1d1d;
$blue: #0033A1;
$blue-light: #42baf9;
$blue-dark: #2c60e4;
$gray-1: #f2f4f8;
$gray-2: #e4e9f0;
$gray-3: #d9dee9;
$gray-4: #c8c4db;
$gray-5: #a1a1c2;
$gray-6: #595c97;
$yellow: #ff0;
$orange: #faad15;
$red: #ff6666;
$pink: #fd3995;
$purple: #652eff;
$green: #66b366;
$light-dark :#7b7b7d;
$light-dark-border:#7b7b7d;
$warn : #f44336;
$light-yellow:#fff3cd;
$fullDark : #000000;


// Accent colors
$default: $gray-4;
$primary: $blue;
$secondary: $gray-5;
$success: $green;
$info: $blue-light;
$warning: $orange;
$danger: $red;
$light: $gray-1;
$dark: $black;

$text: $gray-6;
$border: $gray-2;

//Light Gray
$light-gray-1: #404040;
$light-gray-2: #626262;
$light-gray-3: #979797;