// Font Family
$base-font-family: var(--kit-font-family);

// Font Size
$base-font-size: 15 !default;

//menu hover background color 
$menu-hover-color : #322f4b;

// Shadows
$shadow: 0 0 40px -10px rgba($black, 0.2);
$shadow-2: 0 4px 38px 0 rgba($black, 0.11), 0 0 21px 0 rgba($black, 0.05);
$shadow-3: 0 0 100px -30px rgba(57, 55, 73, 0.3);
$shadow-4: 0 4px 10px 0 rgba($black, 0.03), 0 0 10px 0 rgba($black, 0.02);
$shadow-5: 0 0 40px -10px rgba($black, 0.4);
$shadow-ant: 0 10px 35px -5px rgba(0, 0, 0, 0.15);

// Convert value of rem() sass mixin function
@function rem($px, $base: $base-font-size) {
    @return #{calc(floor(calc($px / $base) * 100) / 100)}rem; // to REMs
  // @return #{$px}px; // to PX's
}

// Transitions
@mixin transition-bg() {
    transition: background 0.2s ease-in-out;
}
@mixin transition-color() {
    transition: color 0.2s ease-in-out;
}
@mixin transition-fast() {
    transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
    transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
    transition: all 0.2s ease-in-out;
}

// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$lg-min-width: 992px;
$lg-max-width: 991px;
$md-min-width: 768px;
$md-max-width: 767px;
$sm-min-width: 576px;
$sm-max-width: 575px;

/* Extra small devices (phones, 600px and down) */
@mixin xs {
    @media (max-width: #{$sm-min-width}) {
        @content;
    }
}

// Small devices
/* Small devices (portrait tablets and large phones, 600px and up) */
@mixin sm {
    @media (min-width: #{$sm-min-width}) {
        @content;
    }
}

// Medium devices
/* Medium devices (landscape tablets, 768px and up) */
@mixin md {
    @media (min-width: #{$md-min-width}) {
        @content;
    }
}

// Large devices
/* Large devices (laptops/desktops, 992px and up) */
@mixin lg {
    @media (min-width: #{$lg-min-width}) {
        @content;
    }
}

// Extra large devices
/* Extra large devices (large laptops and desktops, 1200px and up) */
@mixin xl {
    @media (min-width: #{$xl-min-width}) {
        @content;
    }
}

@mixin rwd($screen) {
    @media (min-width: #{$screen+'px'}) {
        @content;
    }
}

//landscape Setting

@mixin md-landscape {
    
    @media screen and (min-width:#{$md-min-width}) and (max-width:#{$xl-min-width}) and (orientation:landscape){
        @content;
    }
}

//@media all and (orientation:landscape) { … }