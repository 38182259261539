/*  "MEASUREMENTS" STYLES */

// font size
.font-size {
    &-0 {
        font-size: 0px;
    }
    &-10 {
        font-size: 10px;
    }
    &-12 {
        font-size: 12px;
    }
    &-14 {
        font-size: 14px;
    }
    &-16 {
        font-size: 16px;
    }
    &-18 {
        font-size: 18px;
    }
    &-20 {
        font-size: 20px;
    }
    &-22 {
        font-size: 22px;
    }
    &-24 {
        font-size: 24px;
    }
    &-26 {
        font-size: 26px;
    }
    &-28 {
        font-size: 28px;
    }
    &-30 {
        font-size: 30px;
    }
    &-32 {
        font-size: 32px;
    }
    &-36 {
        font-size: 36px;
    }
    &-40 {
        font-size: 40px;
    }
    &-48 {
        font-size: 48px;
    }
    &-50 {
        font-size: 50px;
    }
    &-54 {
        font-size: 54px;
    }
    &-60 {
        font-size: 60px;
    }
    &-70 {
        font-size: 70px;
    }
    &-80 {
        font-size: 80px;
    }
}

// quick height
.height {
    &-0 {
        height: 0px;
    }
    &-10 {
        height: 10px;
    }
    &-20 {
        height: 20px;
    }
    &-40 {
        height: 40px;
    }
    &-100 {
        height: 100px;
    }
    &-150 {
        height: 150px;
    }
    &-200 {
        height: 200px;
    }
    &-250 {
        height: 250px;
    }
    &-300 {
        height: 300px;
    }
    &-400 {
        height: 400px;
    }
    &-500 {
        height: 500px;
    }
    &-600 {
        height: 600px;
    }
    &-700 {
        height: 700px;
    }
    &-0p {
        height: 0%;
    }
    &-10p {
        height: 10%;
    }
    &-20p {
        height: 20%;
    }
    &-25p {
        height: 25%;
    }
    &-33p {
        height: 33%;
    }
    &-50p {
        width: 50%;
    }
    &-66p {
        height: 66%;
    }
    &-75p {
        height: 75%;
    }
    &-80p {
        height: 80%;
    }
    &-90p {
        height: 80%;
    }
    &-100p {
        height: 100%;
    }
}

    // quick width
.width {
    &-10 {
        width: 10px;
    }
    &-20 {
        width: 20px;
    }
    &-40 {
        width: 40px;
    }
    &-50 {
        width: 50px;
    }
    &-100 {
        width: 100px;
    }
    &-150 {
        width: 150px;
    }
    &-200 {
        width: 200px;
    }
    &-250 {
        width: 250px;
    }
    &-300 {
        width: 300px;
    }
    &-350 {
        width: 350px;
    }
    &-400 {
        width: 400px;
    }
    &-500 {
        width: 500px;
    }
    &-600 {
        width: 600px;
    }
    &-700 {
        width: 700px;
    }
    &-0p {
        width: 0%;
    }
    &-9p {
        width: 9%;
    }
    &-10p {
        width: 10%;
    }
    &-20p {
        width: 20%;
    }
    &-25p {
        width: 25%;
    }
    &-30p {
        width: 30%;
    }
    &-33p {
        width: 33%;
    }
    &-40p {
        width: 40%;
    }
    &-50p {
        width: 50%;
    }
    &-55p {
        width: 55%;
    }
    &-60p {
        width: 60%;
    }
    &-66p {
        width: 66%;
    }
    &-70p {
        width: 70%;
    }
    &-75p {
        width: 75%;
    }
    &-80p {
        width: 80%;
    }
    &-90p {
        width: 90%;
    }
    &-100p {
        width: 100%;
    }
}

.line-height-1 {
    line-height: 1;
}

.border-5 {
    border-width: 5px !important;
}

.font-weight {
    &-100 {
        font-weight: 100;
    }
    &-200 {
        font-weight: 200;
    }
    &-300 {
        font-weight: 300;
    }
    &-400 {
        font-weight: 400;
    }
    &-500 {
        font-weight: 500;
    }
    &-600 {
        font-weight: 600;
    }
    &-700 {
        font-weight: 700;
    }
    &-800 {
        font-weight: 800;
    }
    &-900 {
        font-weight: 900;
    }
    &-bold {
        font-weight: bold;
    }
    &-bolder {
        font-weight: bolder;
    }
    &-lighter {
        font-weight: lighter;
    }
    
}