@import '../../themes/variables/mixins.scss';


.contact-details-section{
    
    .business-details{
        margin-left: 10px;

        .business-details-name{
            line-height: 20px;
        }
    }
}



