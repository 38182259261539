@import './themes/main.scss';

$section-max-width: 1800;

body{
    //background-color: #E7E7E7;
    background-color: transparent;
    max-width: #{$section-max-width}+'px';
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-height: 100vh;
    //border: 1px solid red;
    //background: url(https://apitest.payu.in/public/7166f16967f108fc4c151975a6ef14d7.png) no-repeat center top/cover;
}

.pointer{
    cursor: pointer;
}

.disable-pointer{
    cursor: not-allowed !important;
}

.center{
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.hidden{
    display: none !important;
}

#mainScreenContent{
    display: table;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border-radius: 6px;
    -webkit-transition: .3s ease-out opacity;
    -o-transition: .3s ease-out opacity;
    transition: .3s ease-out opacity;
    opacity: 0;
}

#mainScreenContent.ubharja {
    opacity: 1 !important;
}

.envMode{
    text-decoration: none;
    background: rgb(214, 68, 68);
    border: 1px dashed white;
    padding: 3px;
    opacity: 1;
    transform: rotate(45deg);
    transition: opacity 0.3s ease-in 0s;
    color: white;
    position: absolute;
    width: 200px;
    text-align: center;
    right: -50px;
    top: 30px;

    p{
        text-align: center;
        margin-bottom: 0px;
        padding: 5px 0px;
    }
}

.clickEffect:after {
    content: "";
    background: #eeeded;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px!important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}
  
.clickEffect:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}

.payment-section{
    position: absolute;
    width: 100%;
    bottom: 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 9;

    .paynow-title{
        line-height: 22px;
    }
}

.body-container{
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
    background: #F7F7F7;
}

.body-container::-webkit-scrollbar {
    display: none;
}

.bottom-section{
    position: absolute;
    width: 100%;
    bottom: 10px;
    padding: 10px;
    left: 0;
}

.ipayLoaderPrimary{
    color: $primary !important;
}

/**** Dialog Secton **********/

.ipayModal{
    position:absolute !important;
    bottom: unset !important;

    .MuiPaper-root{
        margin : 5px;
    }
}

.ipayModalBottom{
    position:absolute !important;
    top: unset !important;

    .MuiPaper-root{
        margin : 0px;
        width: 100%;
    }
}

.ipayConfirmationBottom{

    position:absolute !important;
    //transition: all .3s ease-out;
    //top: unset !important;
    //height: 560px;
    //bottom: -20px;

    .MuiPaper-root{
        position: absolute !important;
        width: 100%;
        //height: 400px;
        //margin : 0px;
        //height: 100%;
        //width: 50%;

        //overflow-y: hidden;
        //max-height: 500px;
        height: 360px;
    }
}




/**** End Dialog Section *****/

/**** Radio Secton **********/
.ipayRadio{

    .MuiButtonBase-root{
        color: $gray-4
    }
}


/**** End Radio Section *****/


/**** Input Secton **********/
.ipayInput{

    .MuiFormLabel-root.Mui-focused{
        color: $light-gray-3;
    }

    .MuiFormLabel-root.Mui-error.Mui-focused{
        color: #d32f2f;
    }

    .MuiFormLabel-root.Mui-focused{
        color: $light-gray-3;
    }

    .MuiInputBase-root.Mui-focused{

        .MuiOutlinedInput-notchedOutline{
            border-color: #C4C4C4;
            border-width: 1px;
        }
    }

    .MuiInputBase-root.Mui-error.Mui-focused{

        .MuiOutlinedInput-notchedOutline{
            border-color: #d32f2f;
            border-width: 1px;
        }
    }
}


/**** End Input Section *****/


/**** Backdrop Secton **********/
.ipayBackdropTransparent.MuiBackdrop-root{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.2);
}


/**** End Radio Section *****/

/***** Animation *****/

.got-error {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-animation-name: horizontalShake;
    animation-name: horizontalShake;
    -webkit-animation-duration: 400ms;
    animation-duration: 400ms;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

@keyframes horizontalShake {
    0% {
        -webkit-transform: none;
        transform: none;
    }
    12% {
        -webkit-transform: translateX(-6px);
        transform: translateX(-6px);
    }
    37% {
        -webkit-transform: translateX(5px);
        transform: translateX(5px);
    }
    62% {
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
    }
    87% {
        -webkit-transform: translateX(2px);
        transform: translateX(2px);
    }
    100% {
        -webkit-transform: none;
        transform: none;
    }
}


/****** End Animation *****/


/*** On Hover UnderLine ***/

.hover-underline-primary:hover{
    text-decoration: underline;
    text-decoration-color: $primary;
}

.hover-underline-danger:hover{
    text-decoration: underline;
    text-decoration-color: $warn;
}

/*** End On Hover UnderLine ***/

/****** Notification ********/

.customNotify{

    position: relative !important;
    width: 95% !important;
    /* display: table;
    margin-left: auto;
    margin-right: auto; */
    right: 0;
    padding: 0px;
    top: 15px;

    .Toastify__toast{
        position: absolute !important;
        width: 100%;
    }
}


/****** End Notification ********/

/***** Confirmation Screen ******/

.paymentBodySection{
    position:absolute;
    height:100%;
    width:100%;
    z-index: 9;
}


.paymentConfirmationSection{
    position: absolute;
    height: 100%;
    width: 100%; 
    top: 100%;
    background-color: aliceblue; //'#ffffff'
    transition: all .6s ease-out;
}

.showPaymentConfirmationSection{
    top: 0 !important;
    z-index: 99;
}

.successBackground {
    background: linear-gradient(134deg, #549048 -0.89%, #28671C 99.91%);
    height: 70%;
}

.pendingBackground {
    background: linear-gradient(315deg, #FFC043 0.07%, #FFCF6F 99.75%);
    height: 70%;
}

.failedBackground{
    background: linear-gradient(315deg, #E11900 0.32%, #F96C5B 99.94%);
    height: 70%;
}

/***** End Confirmation Screen ******/

/******* Button Section *********/

.btn-primaryBorderWithWiteFill{
    border: 1px solid $primary !important;
    color: $primary !important;
} 



/******* End Button Section *********/

/***** Button Animation ********/

.horizontal-shake {
    animation: horizontal-shaking 0.35s 1;
}

@keyframes horizontal-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
}

/***** End Button Animation ********/
