.certified-section{
    
    img{
        margin-right: 10px;
    }
}

.secure-section{

    .title{
        margin-right: 10px;
    }
    
    .footer-logo{
        width: 30%;
    }
}